import { Redirect, useLocation } from 'react-router-dom';
import React from 'react';

function ProtectedAuth({ isLoggedIn, children }) {
  const location = useLocation();
  const path = location.pathname;

  if (!isLoggedIn) {
    return <>{children}</>;
  }

  const restrictedPaths = ['/signup', '/login', '/forgot-password'];
  const shouldRedirectToHome =
    restrictedPaths.includes(path) ||
    path.includes('reset-password') ||
    path.includes('signup');

  if (shouldRedirectToHome) {
    return <Redirect to="/" />;
  }

  return (
    <Redirect to={{ pathname: path, state: { toast: true, login: true } }} />
  );
}

export default ProtectedAuth;
