import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer } from './utils/toast';
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer';
import './styles/global.css';
import './styles/responsive.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import ProtectedRoutes from './components/ProtectedRoutes/ProtectedRoutes';
import ProtectedAuth from './components/ProtectedRoutes/ProtectedAuth';

const Layout = lazy(() => import('./layout/Layout'));
const Login = lazy(() => import('./pages/Login'));
const SignUp = lazy(() => import('./pages/SignUp'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));

function App() {
  const { isLoggedIn } = useSelector((state) => state.auth);

  return (
    <>
      <ToastContainer />
      <Router>
        <AccessibleNavigationAnnouncer />
        <Suspense fallback={<div>Loading...</div>}>
          <ProtectedAuth isLoggedIn={isLoggedIn}>
            <Switch>
              <Route exact path="/signup/:refcode?" component={SignUp} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/login" component={Login} />
              <Route
                exact
                path="/reset-password/:token"
                component={ResetPassword}
              />
            </Switch>
          </ProtectedAuth>
          <ProtectedRoutes isLoggedIn={isLoggedIn}>
            <Switch>
              <Route path="/" component={Layout} />
            </Switch>
          </ProtectedRoutes>
        </Suspense>
      </Router>
    </>
  );
}

export default App;
