import { Redirect, useLocation } from 'react-router-dom';
import React, { memo } from 'react';

function ProtectedRoutes({ isLoggedIn, children }) {
  const location = useLocation();
  const path = location.pathname;

  if (isLoggedIn) {
    return <>{children}</>;
  }

  if (
    path === '/signup' ||
    path === '/forgot-password' ||
    path.includes('reset-password') ||
    path.includes('signup')
  ) {
    return <Redirect to={path} />;
  }

  return <Redirect to="/login" />;
}

export default memo(ProtectedRoutes);
