import {
  GET_WITHDRAWLS_LIST,
  GET_WITHDRAWLS_LIST_ERROR,
  NEW_WITHDRAWL,
} from '../types';

const initialState = {
  withdrawlsLists: [],
  success: false,
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_WITHDRAWLS_LIST:
      return {
        ...state,
        withdrawlsLists: action.payload,
        success: true,
        loading: false,
      };
    case GET_WITHDRAWLS_LIST_ERROR:
      return {
        ...state,
        success: false,
        loading: false,
      };
    case NEW_WITHDRAWL:
      return {
        ...state,
        success: true,
        loading: false,
      };

    default:
      return state;
  }
}
