import React, { useState, createContext, useMemo } from 'react';

// create context
export const SidebarContext = createContext();

export function SidebarProvider({ children }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const closeSidebar = () => setIsSidebarOpen(false);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const closeDrawer = () => setIsDrawerOpen(false);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const closeModal = () => setIsModalOpen(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const contextValue = useMemo(
    () => ({
      isSidebarOpen,
      toggleSidebar,
      closeSidebar,
      isDrawerOpen,
      toggleDrawer,
      closeDrawer,
      setIsDrawerOpen,
      isModalOpen,
      toggleModal,
      closeModal,
      isUpdate,
      setIsUpdate,
    }),
    [isSidebarOpen, isDrawerOpen, isModalOpen, isUpdate]
  );

  return (
    <SidebarContext.Provider value={contextValue}>
      {children}
    </SidebarContext.Provider>
  );
}
